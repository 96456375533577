export type Order = {
  orderId: string
  creationDate: string
  actualStatus: number
  orderHistoryList: OrderHistory[]
  storeBrand: StoreBrand
  appointmentLink: string
  contactUpsPhoneTime: string
  contactuUsphone: string
  whatsAppLink: string
}

export enum OrderStep {
  Received = 1,
  Processing = 2,
  ReadyToShip = 3,
  Sent = 4,
  Delivered = 5,
  Canceled = 6,
}

export type OrderHistory = {
  statusFrom: OrderStep
  statusTo: OrderStep
  location: string
  statusUpdateDate: string
}

export type StoreBrand = 'SV' | 'PS' | 'RB' | 'OP'

export type StoreBrandNames = Record<StoreBrand, string>
