import { Box } from '@material-ui/core'

export const ContactBox: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <Box
      className={className}
      sx={{
        p: 2,
        bgcolor: 'secondary.main',
        height: '100%',
      }}
    >
      {children}
    </Box>
  )
}
