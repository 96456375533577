import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline, responsiveFontSizes, Theme } from '@material-ui/core'
import theme from '../theme'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useTheme = () => {
  const { storeBrand } = useSelector((s) => s.order.order)
  return useMemo(() => {
    const th = theme[storeBrand] || theme.default

    return responsiveFontSizes(th)
  }, [storeBrand])
}

interface ThemeProps {
  theme: Theme
}

const ThemeWrapper: React.FC<ThemeProps> = ({ children, theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export const withTheme = <T extends {}>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    const defaultTheme = useTheme()
    return (
      <ThemeWrapper theme={defaultTheme}>
        <Component {...props} />
      </ThemeWrapper>
    )
  }
}
