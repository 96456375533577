import { defaultTheme as sv } from './sv.theme'
import { defaultTheme as persol } from './persol.theme'
import { defaultTheme as oliver } from './oliver.theme'
import { defaultTheme as rb } from './rb.theme'
import { Theme } from '@material-ui/core'

const theme: Record<string, Theme> = {
  SV: sv,
  PS: persol,
  OP: oliver,
  RB: rb,
  default: sv,
}

export default theme
