import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import TrackerOrderService from '../../services/trackerOrder.service'
import { Order, StoreBrand } from '../../types/order'

interface OrderState {
  isLoading: boolean
  error: Error | null
  order: Order
}

const initialState: OrderState = {
  isLoading: false,
  error: null,
  order: {
    orderId: '',
    creationDate: '',
    actualStatus: 0,
    orderHistoryList: [],
    storeBrand: 'SV',
    whatsAppLink: '',
    contactUpsPhoneTime: '',
    contactuUsphone: '',
    appointmentLink: '',
  },
}

export const getOrderData = createAsyncThunk(
  'ot-ui/order/getOrder',
  (hashOrder: string) => {
    const trackerOrderService = new TrackerOrderService()
    return trackerOrderService.get<Order>(
      `/retrieveorderstatusinfo?hashOrder=${hashOrder}`
    )
  }
)

const order = createSlice({
  name: 'ot-ui/order',
  initialState,
  reducers: {},
  extraReducers: {
    [getOrderData.rejected.toString()]: (
      state,
      action: PayloadAction<Order, string, unknown, Error>
    ) => {
      state.isLoading = false
      state.error = action.error
    },
    [getOrderData.fulfilled.toString()]: (
      state,
      action: PayloadAction<Order>
    ) => {
      state.isLoading = false
      state.error = null
      state.order = {
        ...action.payload,
        storeBrand: action.payload.storeBrand.toUpperCase() as StoreBrand,
      }
    },
    [getOrderData.pending.toString()]: (state) => {
      state.isLoading = true
      state.error = null
    },
  },
})

export default order.reducer
