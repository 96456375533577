import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel, Box, StepConnector } from '@material-ui/core'
import { useSteps } from '../../hooks/useSteps'
import { OrderStep } from '../../types/order.d'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default,
    paddingTop: '2rem',
    paddingBottom: theme.spacing(6),
  },
  stepper: {
    padding: 0,
    backgroundColor: 'inherit',
  },
  label: {
    '& .MuiStepLabel-iconContainer': {
      zIndex: 1,
    },
    '& .MuiStepLabel-label': {
      color: theme.palette.text.disabled,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    '& .MuiStepLabel-completed': {
      color: theme.palette.text.hint,
    },
    '& .MuiStepLabel-active': {
      color: theme.palette.text.hint,
      display: 'block',
    },
  },
  labelContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      width: '50vw',
      top: 8,
    },
  },
}))

const ConnectorStyled = withStyles((theme: Theme) => ({
  active: {
    '& $line': {
      backgroundColor: theme.palette.secondary.dark,
      '@media print': {
        border: `2px solid ${theme.palette.secondary.dark}`,
      },
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.secondary.dark,
      '@media print': {
        border: `2px solid ${theme.palette.secondary.dark}`,
      },
    },
  },
  line: {
    border: 0,
    backgroundColor: theme.palette.secondary.light,
    '@media print': {
      border: `2px solid ${theme.palette.secondary.light}`,
    },
  },
}))(StepConnector)

export const OrderStatus: React.FC<{ orderStatus: OrderStep }> = ({
  orderStatus,
}) => {
  const classes = useStyles()
  const steps = useSteps(orderStatus === OrderStep.Canceled)
  const activeStep = steps.findIndex((step) => step.value === orderStatus)
  const isLastStep = activeStep === steps.length - 1

  return (
    <Box className={classes.wrapper}>
      <Stepper
        activeStep={activeStep !== -1 ? activeStep : 0}
        alternativeLabel
        className={classes.stepper}
        connector={<ConnectorStyled />}
      >
        {steps.map((step) => (
          <Step key={step.label} {...(isLastStep && { completed: true })}>
            <StepLabel
              classes={{
                labelContainer: classes.labelContainer,
              }}
              className={classes.label}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
