import { configureStore } from '@reduxjs/toolkit'
import rootReducer, { RootState } from './rootReducer'
import reset from '../features/reset/resetAppSlice'

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultRootState extends RootState {}
}

const resettableRootReducer: typeof rootReducer = (state, action) => {
  if (action.type === reset.actions.resetApp.type) {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

const store = configureStore({
  reducer: resettableRootReducer,
})

export type AppReduxStore = typeof store

export type Reducers = keyof RootState

export default store
