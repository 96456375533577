export const PrintIcon: React.FC<{
  fill?: string
  width?: string
  height?: string
}> = ({ fill = '#2f3f54', width = '100%', height = '100%' }) => {
  return (
    <svg viewBox="0 0 24 24" fill={fill} width={width} height={height}>
      <path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"></path>
    </svg>
  )
}
