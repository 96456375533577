import { RouteComponentProps } from 'react-router-dom'
import { Typography, Box } from '@material-ui/core'
import { useTranslation } from '../hooks/useTranslation'

export const PageNotFound: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{ margin: '50px auto' }}>
      <Typography variant="h2">{t('pageNotFoundMessage')}</Typography>
    </Box>
  )
}
