const safeEnv = (key: string) => {
  const value = process.env[key]
  if (value === undefined) throw new Error(`Missing env variable ${key}`)
  return value
}

const defaultConfig = {
  apiUrl: safeEnv('REACT_APP_API_URL'),
}

export type AppConfig = typeof defaultConfig
const config: AppConfig = defaultConfig

export default config
