import { createTheme } from '@material-ui/core/styles'
import { Overrides } from '@material-ui/core/styles/overrides'
import { Palette } from '@material-ui/core/styles/createPalette'
import { commonTheme } from './commonTheme'

const palette: Partial<Palette> = {
  common: {
    black: '#303030',
    white: '#ffffff',
  },
  primary: {
    main: '#303030',
    dark: '#e80c00',
    light: '#f2f2f2',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#f8f8f8',
    dark: '#e80c00',
    light: '#d6d6d6',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#1f1f24',
    secondary: '#1f1f24',
    disabled: '#d6d6d6',
    hint: '#1f1f24',
  },
  divider: '#ffffff',
  background: {
    default: '#ffffff',
    paper: '#f0f3f6',
  },
  error: {
    main: '#d32f2f',
    dark: '#c62828',
    light: '#ef5350',
    contrastText: '#ffffff',
  },
}

const fonts = {
  primary: 'Oswald, sans-serif',
  secondary: 'Lato, Roboto, sans-serif',
}

const typography = {
  fontFamily: fonts.primary,
  fontSize: 14,
  h1: {
    fontSize: '1.7rem',
    color: palette.text?.secondary,
    textTransform: 'uppercase' as const,
    fontWeight: 500,
    marginBottom: 20,
  },
  h2: {
    fontSize: '1.7rem',
    color: palette.text?.secondary,
    textTransform: 'uppercase' as const,
    fontWeight: 500,
    marginBottom: 20,
  },
  h3: {
    fontSize: '1.7rem',
    color: palette.text?.secondary,
    textTransform: 'uppercase' as const,
    fontWeight: 500,
    marginBottom: 20,
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    color: palette.text?.primary,
  },
  subtitle1: {
    fontSize: '1rem',
    color: palette.text?.primary,
    textTransform: 'uppercase' as const,
    fontWeight: 500,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '1rem',
    color: palette.text?.primary,
  },
  body1: {
    fontFamily: fonts.secondary,
    fontSize: '0.93rem',
  },
  body2: {
    color: palette.text?.secondary,
    letterSpacing: 0.6,
    fontFamily: fonts.secondary,
    fontSize: '0.93rem',
  },
}

const overrides: Overrides = {
  MuiButton: {
    root: {
      fontSize: typography.fontSize,
      borderRadius: 1,
    },
    contained: {
      backgroundColor: palette.primary?.dark,
      color: palette.primary?.contrastText,
      '&:hover': {
        backgroundColor: palette.primary?.main,
      },
    },
    outlinedPrimary: {
      backgroundColor: palette.common?.black,
      color: palette.primary?.contrastText,
      '&:hover': {
        backgroundColor: palette.secondary?.main,
        color: palette.primary?.main,
      },
    },
  },
  MuiStepLabel: {
    label: {
      fontWeight: 500,
      fontSize: '1rem',
      fontFamily: fonts.primary,
    },
  },
  MuiStepIcon: {
    root: {
      width: 24,
      height: 24,
    },
    active: {
      fill: palette.secondary?.dark,
    },
    completed: {
      fill: palette.secondary?.dark,
    },
  },
  MuiStepConnector: {
    line: {
      height: 8,
    },
    alternativeLabel: {
      top: 8,
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
    },
  },
}

export const defaultTheme = createTheme({
  palette,
  typography,
  overrides,
  header: {
    paddingTop: '0',
    logoPosition: 'flex-start',
  },
  footer: {
    ...commonTheme.footer,
    logoDisplay: 'none',
  },
})
