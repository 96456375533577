import { createTheme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'
import { Palette } from '@material-ui/core/styles/createPalette'
import { commonTheme } from './commonTheme'

const palette: Partial<Palette> = {
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  primary: {
    main: '#435975',
    dark: '#2f3f54',
    light: '#f2f2f2',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#f8f8f8',
    dark: '#435975',
    light: '#d6d6d6',
    contrastText: '#595959',
  },
  text: {
    primary: '#303030',
    secondary: '#595959',
    disabled: '#d6d6d6',
    hint: '#435975',
  },
  divider: '#f2f2f2',
  background: {
    default: '#ffffff',
    paper: '#f0f3f6',
  },
  error: {
    main: '#d32f2f',
    dark: '#c62828',
    light: '#ef5350',
    contrastText: '#ffffff',
  },
}

const fonts = {
  primary: 'Nunito Sans, Roboto, sans-serif',
  secondary: 'Oswald, sans-serif',
}

const typography = {
  fontFamily: fonts.primary,
  fontSize: 14,
  h1: {
    fontFamily: fonts.secondary,
    fontSize: '1.7rem',
    color: palette.common?.black,
    textTransform: 'uppercase' as const,
    fontWeight: 400,
    marginBottom: 20,
  },
  h2: {
    fontFamily: fonts.secondary,
    fontSize: '1.7rem',
    color: palette.common?.black,
    textTransform: 'uppercase' as const,
    fontWeight: 400,
    marginBottom: 20,
  },
  h3: {
    color: palette.common?.black,
    fontSize: '1.285rem',
    fontWeight: 600,
  },
  h5: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: palette.text?.primary,
  },
  subtitle1: {
    letterSpacing: 0.6,
    fontWeight: 600,
    color: palette.common?.black,
    fontSize: 20,
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: palette.text?.primary,
  },
  body2: {
    color: palette.text?.secondary,
    letterSpacing: 0.6,
  },
}

const overrides: Overrides = {
  MuiButton: {
    root: {
      fontFamily: fonts.secondary,
      fontSize: typography.fontSize,
      borderRadius: 6,
    },
    contained: {
      backgroundColor: palette.primary?.dark,
      color: palette.primary?.contrastText,
      '&:hover': {
        backgroundColor: palette.primary?.main,
      },
    },
  },
  MuiLink: {
    root: {
      letterSpacing: 'normal',
    },
  },
  MuiStepLabel: {
    label: {
      fontWeight: 600,
      fontSize: '1.285rem',
    },
  },
  MuiStepConnector: {
    line: {
      height: 4,
    },
    alternativeLabel: {
      top: 8,
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
    },
  },
}

export const defaultTheme = createTheme({
  palette,
  typography,
  overrides,
  ...commonTheme,
})
