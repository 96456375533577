import { Grid, Box, Typography, Link, useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from '../../hooks/useTranslation'
import { Order } from '../../types/order'
import { PrintIcon } from '../Icons/PrintIcon'
import { useDate } from '../../hooks/useDate'

const useStyles = makeStyles((theme: Theme) => ({
  orderNumberWrapper: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  orderDateTitle: {
    color: theme.palette.text.primary,
  },
  orderDate: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontFamily: theme.typography.fontFamily,
  },
  orderDateWrapper: {
    width: '100%',
    padding: '1rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  printBox: {
    display: 'flex',
    alignItems: 'center',
    '@media print': {
      display: 'none',
    },
  },
  icon: {
    display: 'inline-block',
    width: '2rem',
    marginRight: '1rem',
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}))

interface OrderHeaderProps {
  order: Order
}

export const OrderHeader: React.FC<OrderHeaderProps> = ({ order }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { transformDate } = useDate()
  const printVisible = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('sm')
  )

  const print = () => {
    window.print()
  }
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid container item xs={12} sm={6}>
        <Typography variant="h1" component="p">
          {t('orderTraker')}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        className={classes.orderNumberWrapper}
      >
        <Typography variant="h3" component="p">{`${t('orderNumber')}: ${
          order.orderId
        }`}</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Box
          className={classes.orderDateWrapper}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={classes.orderDateTitle}>
            {t('orderDate')}:{' '}
            <Box component="span" className={classes.orderDate}>
              {transformDate(order.creationDate)}
            </Box>
          </Typography>
          {printVisible && (
            <Link
              color="inherit"
              underline="always"
              component="button"
              onClick={print}
              className={classes.printBox}
            >
              <span className={classes.icon}>
                <PrintIcon />
              </span>
              {t('orderPrint')}
            </Link>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
