import { useMemo } from 'react'
import { useTranslation as useI18nTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { StoreBrandNames } from '../types/order'

const namespaces: StoreBrandNames = {
  SV: 'translation',
  PS: 'persol',
  OP: 'oliver',
  RB: 'translation',
}

export const useTranslation = () => {
  const { storeBrand } = useSelector((s) => s.order.order)
  const { t } = useI18nTranslation(namespaces[storeBrand] || 'translation')

  return useMemo(() => {
    return { t }
  }, [t])
}
