import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { svStoreInfo } from '../storeInfo/sv'
import { persolStoreInfo } from '../storeInfo/persol'
import { oliverStoreInfo } from '../storeInfo/oliver'
import { rbStoreInfo } from '../storeInfo/rb'
import { BaseStoreData } from '../types/storeType'

export const useStoreInfo = (): BaseStoreData => {
  const { storeBrand } = useSelector((s) => s.order.order)
  const storeInfo = useMemo(() => {
    switch (storeBrand) {
      case 'SV':
        return svStoreInfo
      case 'PS':
        return persolStoreInfo
      case 'OP':
        return oliverStoreInfo
      case 'RB':
        return rbStoreInfo
      default:
        return svStoreInfo
    }
  }, [storeBrand])

  return storeInfo
}
