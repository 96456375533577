import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell, { TableCellProps } from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { OrderHistory } from '../../types/order'
import { useTranslation } from '../../hooks/useTranslation'
import { Typography, useMediaQuery } from '@material-ui/core'
import { useDate } from '../../hooks/useDate'

const useStyles = makeStyles((theme: Theme) => ({
  headRoot: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'table-header-group',
    },
  },
  bodyRoot: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'table-row-group',
    },
  },
  rowRoot: {
    display: 'block',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    verticalAlign: 'baseline',
    lineHeight: 1.43,
    '&:last-child': {
      border: 'none',
      paddingBottom: '0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1.2rem 0',
      display: 'table-row',
    },
  },
  cellRoot: {
    display: 'block',
    padding: '0.2rem 0',
    fontSize: '1rem',
    borderBottom: 'none',
    '&:last-child': {
      width: '40%',
      paddingBottom: '1.43rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '&:first-child': {
      paddingTop: '1.43rem',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1.43rem 0',
      display: 'table-cell',
      width: '30%',
    },
  },
  cellHead: {
    display: 'block',
    padding: '0.2rem 0',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    '&:last-child': {
      width: '40%',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'table-cell',
      width: '30%',
    },
  },
}))

export const FIRST_UPDATE_NUMBER = 1
export const LAST_UPDATE_NUMBER = 5

const CellStyled: React.FC<TableCellProps> = ({ children }) => {
  const classes = useStyles()
  return <TableCell classes={{ root: classes.cellRoot }}>{children}</TableCell>
}

const CellHeadStyled: React.FC<TableCellProps> = ({ children }) => {
  const classes = useStyles()
  return (
    <TableCell classes={{ head: classes.cellHead }}>
      <Typography variant="subtitle1">{children}</Typography>
    </TableCell>
  )
}

interface OrderHistoryTableProps {
  orderHistory: OrderHistory[]
}

export const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({
  orderHistory,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { transformDate } = useDate()

  return (
    <>
      <Typography variant="h2" component="p">
        {t('orderHistory')}
      </Typography>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead classes={{ root: classes.headRoot }}>
            <TableRow classes={{ root: classes.rowRoot }}>
              <CellHeadStyled>{t('date')}</CellHeadStyled>
              <CellHeadStyled>{t('location')}</CellHeadStyled>
              <CellHeadStyled>{t('event')}</CellHeadStyled>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.bodyRoot }}>
            {orderHistory.map((orderHistory, ind) => (
              <TableRow key={ind} classes={{ root: classes.rowRoot }}>
                {!matches && (
                  <CellStyled>
                    <Typography variant="subtitle2">
                      {t(`statusStepLabel${orderHistory.statusTo}`)}
                    </Typography>
                  </CellStyled>
                )}
                <CellStyled>
                  <Typography>
                    {transformDate(orderHistory.statusUpdateDate, {
                      month: 'numeric',
                    })}
                  </Typography>
                </CellStyled>
                <CellStyled>
                  {[FIRST_UPDATE_NUMBER, LAST_UPDATE_NUMBER].includes(
                    orderHistory.statusTo
                  )
                    ? orderHistory.location
                    : t('laboratory')}
                </CellStyled>
                <CellStyled>
                  {matches && (
                    <Typography variant="subtitle2">
                      {t(`statusStepLabel${orderHistory.statusTo}`)}
                    </Typography>
                  )}
                  <Typography>
                    {t(`statusStepInfo${orderHistory.statusTo}`)}
                  </Typography>
                </CellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
