import { Logo } from './Logo'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { ContentContainer } from './ContentContainer'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.background.default,
    margin: 'auto',
    paddingTop: theme.header.paddingTop,
    paddingBottom: '1.85rem',
    display: 'flex',
    justifyContent: theme.header.logoPosition,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '20rem',
      maxHeight: '5.28rem',
    },
    maxWidth: '15rem',
    maxHeight: '3.16rem',
  },
}))

export const Header: React.FC = () => {
  const classes = useStyles()
  const { storeBrand } = useSelector((s) => s.order.order)
  return (
    <ContentContainer className={classes.header}>
      <Logo className={classes.logo} storeType={storeBrand} />
    </ContentContainer>
  )
}
