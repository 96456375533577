import { Logo } from './Logo'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link, Grid, useMediaQuery, Container } from '@material-ui/core'
import { useTranslation } from '../hooks/useTranslation'
import { useStoreInfo } from '../hooks/useStoreInfo'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.background.paper,
    padding: '1rem 3rem',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '1.5rem 5.71rem',
    },
  },
  logo: {
    display: theme.footer.logoDisplay,
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.footer.logoSize.maxWidth,
      maxHeight: '4.43rem',
      paddingBottom: '0',
    },
    maxWidth: theme.footer.logoSize.maxSmWidth,
    maxHeight: '3.16rem',
    paddingBottom: '0.5rem',
  },
  link: {
    '@media print': {
      position: 'relative',
      '&::after': {
        content: '"("attr(href)")"',
        position: 'absolute',
        bottom: '-1em',
        right: 0,
      },
    },
  },
}))

export const Footer: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const { storeBrand } = useSelector((s) => s.order.order)
  const storeInfo = useStoreInfo()

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container justifyContent={matches ? 'space-between' : 'center'}>
          <Grid
            container
            item
            xs={12}
            sm={6}
            justifyContent={matches ? 'flex-start' : 'center'}
          >
            <Logo className={classes.logo} storeType={storeBrand} alternative />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            justifyContent={matches ? 'flex-end' : 'center'}
            alignItems="center"
          >
            {storeInfo.contactUrl && (
              <Link
                color="inherit"
                underline="always"
                component="a"
                href={storeInfo.contactUrl}
                rel="noopener noreferrer"
                target="_blank"
                className={classes.link}
              >
                {t('orderHelp')}
              </Link>
            )}
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}
