export const commonTheme = {
  header: {
    paddingTop: '1.85rem',
    logoPosition: 'center',
  },
  footer: {
    logoDisplay: 'visible',
    logoSize: {
      maxWidth: '20rem',
      maxSmWidth: '15rem',
    },
  },
}
