import { ComponentType } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from '../hooks/useTranslation'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  errorBox: {
    maxWidth: 800,
    width: '100%',
    margin: '50px auto',
    border: '2px solid',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    borderColor: theme.palette.error.light,
  },
}))

export const ErrorFallback: ComponentType<FallbackProps> = ({ error }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Box className={classes.errorBox}>
      <Typography color="error" variant="h2">
        {t('errorInfoMessage')}
      </Typography>
      <Typography component="pre">{error.message}</Typography>
    </Box>
  )
}
