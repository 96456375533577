import { OrderHeader } from './OrderHeader'
import { OrderStatus } from './OrderStatus'
import { OrderHistoryTable, LAST_UPDATE_NUMBER } from './OrderHistory'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { useTranslation } from '../../hooks/useTranslation'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  appointmentButton: {
    marginBottom: '4.28rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      marginLeft: '60%',
    },
    '@media print': {
      backgroundColor: 'white',
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
      position: 'relative',
      '&::after': {
        content: '"("attr(href)")"',
        position: 'absolute',
        bottom: '-3rem',
        right: 0,
        fontSize: '0.5rem',
        left: 0,
      },
    },
  },
}))

export const Order: React.FC = () => {
  const { order } = useSelector((s) => s.order)
  const { t } = useTranslation()
  const classes = useStyles()

  const isLastUpdate =
    order.orderHistoryList[order.orderHistoryList.length - 1]?.statusTo ===
    LAST_UPDATE_NUMBER

  return (
    <>
      <OrderHeader order={order} />
      <OrderStatus orderStatus={order.actualStatus} />
      <OrderHistoryTable orderHistory={order.orderHistoryList} />
      {isLastUpdate && order.appointmentLink && (
        <Button
          className={classes.appointmentButton}
          variant="contained"
          component="a"
          href={order.appointmentLink}
        >
          {t('bookAppointment')}
        </Button>
      )}
    </>
  )
}
