import sv from '../assets/logo/sv.svg'
import oliver from '../assets/logo/op.svg'
import oliverAlt from '../assets/logo/op_alt.svg'
import ps from '../assets/logo/ps.svg'
import rb from '../assets/logo/rb.svg'
import { StoreBrand, StoreBrandNames } from '../types/order'

const logos: StoreBrandNames = {
  SV: sv,
  RB: rb,
  PS: ps,
  OP: oliver,
}

const alternativeLogos: StoreBrandNames = {
  SV: sv,
  RB: rb,
  PS: ps,
  OP: oliverAlt,
}

export const Logo: React.FC<{
  storeType: StoreBrand
  className?: string
  alternative?: boolean
}> = ({ storeType, className, alternative = false }) => {
  const logosList = alternative ? alternativeLogos : logos
  const src = logosList[storeType] || logosList.SV
  return <img className={className} src={src} alt="" />
}
