import { Box, CircularProgress } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 100,
    },
  })
)

export const Loading: React.FC = () => {
  const styles = useStyles()

  return (
    <Box className={styles.loaderWrapper}>
      <CircularProgress />
    </Box>
  )
}
