import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      '@media print': {
        height: 'auto',
        width: '100%',
        pageBreakInside: 'avoid',
      },
    },
    main: {
      flexGrow: 1,
    },
  })
)

export const BaseComponent: React.FC = ({ children }) => {
  const styles = useStyles()

  return (
    <Box className={styles.container}>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </Box>
  )
}
