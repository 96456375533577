import { createTheme } from '@material-ui/core/styles'
import { Overrides } from '@material-ui/core/styles/overrides'
import { Palette } from '@material-ui/core/styles/createPalette'
import { commonTheme } from './commonTheme'
import BerlingRoman from '../assets/fonts/berlingRoman/Berlingltstd-roman.woff2'
import FuturaBook from '../assets/fonts/futuraBook/FuturaStd-Book.woff2'

const berlingRoman = {
  fontFamily: 'BerlingRoman',
  fontWeight: 400,
  src: `local('BerlingRoman'), url(${BerlingRoman}) format('woff2')`,
  fontStyle: 'normal' as const,
}

const futuraBook = {
  fontFamily: 'Futura',
  fontWeight: 400,
  src: `local('Futura'), url(${FuturaBook}) format('woff2')`,
  fontStyle: 'normal' as const,
}

const palette: Partial<Palette> = {
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  primary: {
    main: '#000000',
    dark: '#000000',
    light: '#bfbfbf',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#ffffff',
    light: '#bfbfbf',
    dark: '#000000',
    contrastText: '#000000',
  },
  text: {
    primary: '#69697a',
    secondary: '#202125',
    disabled: '#b6b6c4',
    hint: '#202125',
  },
  divider: '#ffffff',
  background: {
    default: '#ffffff',
    paper: '#f0f3f6',
  },
}

const fonts = {
  primary: `${berlingRoman.fontFamily}, serif`,
  secondary: `${futuraBook.fontFamily}, Roboto, sans-serif`,
}

const typography = {
  fontFamily: fonts.primary,
  fontSize: 14,
  letterSpacing: 'normal',
  h1: {
    fontFamily: fonts.secondary,
    fontSize: '2.29rem',
    textTransform: 'none' as const,
    marginBottom: 20,
    fontWeight: 400,
  },
  h2: {
    fontFamily: fonts.secondary,
    fontSize: '1.57rem',
    textTransform: 'uppercase' as const,
    marginBottom: 25,
    fontWeight: 400,
  },
  h3: {
    fontSize: '1rem',
    marginBottom: 20,
  },
  h5: {
    fontSize: '1rem',
    fontFamily: fonts.secondary,
  },
  subtitle1: {
    fontFamily: fonts.secondary,
    fontSize: '1.4rem',
    fontWeight: 600,
    color: palette.text?.secondary,
  },
  subtitle2: {
    fontSize: '1rem',
    color: palette.text?.secondary,
    fontWeight: 600,
  },
  body2: {
    color: palette.text?.secondary,
    letterSpacing: 1.11,
  },
}

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [futuraBook, berlingRoman],
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
    },
  },
  MuiLink: {
    root: {
      letterSpacing: 'normal',
    },
  },
  MuiTableCell: {
    root: {
      letterSpacing: 'normal',
    },
  },
  MuiStepLabel: {
    label: {
      fontFamily: fonts.secondary,
      fontSize: 14,
      lineHeight: 1.29,
      letterSpacing: 'normal',
      fontWeight: 500,
    },
  },
  MuiStepIcon: {
    root: {
      width: 14,
      height: 14,
    },
    text: {
      fontSize: 0,
    },
  },
  MuiStepConnector: {
    line: {
      height: 2,
    },
    alternativeLabel: {
      top: 6,
      left: 'calc(-50% + 4px)',
      right: 'calc(50% + 4px)',
    },
  },
}

export const defaultTheme = createTheme({
  palette,
  typography,
  overrides,
  ...{
    ...commonTheme,
    footer: {
      ...commonTheme.footer,
      logoSize: {
        maxWidth: '9.9rem',
        maxSmWidth: '9.9rem',
      },
    },
  },
})
