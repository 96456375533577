import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const defaultDateFormat = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
} as const

export const useDate = () => {
  const { i18n } = useTranslation()

  const transformDate = useCallback(
    (date: string, format: Intl.DateTimeFormatOptions = {}): string => {
      let formatedDate = ''
      try {
        formatedDate = new Intl.DateTimeFormat(i18n.language, {
          ...defaultDateFormat,
          ...format,
        }).format(new Date(date))
      } catch {
        formatedDate = date
      } finally {
      }
      return formatedDate
    },
    [i18n]
  )

  return useMemo(
    () => ({
      transformDate,
    }),
    [transformDate]
  )
}
