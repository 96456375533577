import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.72rem 1.43rem 2.85rem',
    [theme.breakpoints.up('sm')]: {
      padding: '2.85rem 5.71rem',
    },
  },
}))

export const ContentContainer: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const classes = useStyles()
  return (
    <Container disableGutters className={`${classes.container} ${className}`}>
      <>{children}</>
    </Container>
  )
}
