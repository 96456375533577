import { RouteComponentProps } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { BaseComponent } from '../components/BaseComponent'
import { Order } from '../components/Order'
import { useTranslation } from '../hooks/useTranslation'
import { ContactInfo } from '../components/ContactInfo'
import { useQuery } from '../hooks/useQuery'
import { getOrderData } from '../features/order/orderSlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../components/Loading'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '1.72rem 1.43rem 2.85rem',
    [theme.breakpoints.up('sm')]: {
      padding: '2.85rem 5.71rem',
    },
  },
}))

export const OrderPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const hash = useQuery().get('hashOrder') || ''
  const dispatch = useDispatch()

  if (!hash) {
    throw new Error(t('noHashOrderMessage'))
  }

  useEffect(() => {
    dispatch(getOrderData(hash))
  }, [dispatch, hash])

  const { isLoading, error, order } = useSelector((s) => s.order)

  if (error) {
    throw new Error(error.message)
  }

  if (isLoading || !order.orderId) {
    return <Loading />
  }

  return (
    <BaseComponent>
      <Container disableGutters className={classes.container}>
        <Order />
        <ContactInfo />
      </Container>
    </BaseComponent>
  )
}
