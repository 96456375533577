import { createTheme } from '@material-ui/core/styles'
import { Overrides } from '@material-ui/core/styles/overrides'
import { Palette } from '@material-ui/core/styles/createPalette'
import { commonTheme } from './commonTheme'
import Caboto from '../assets/fonts/caboto/CABOTO-Regular.woff2'

const caboto = {
  fontFamily: 'Caboto',
  fontWeight: 400,
  src: `local('Caboto'), url(${Caboto}) format('woff2')`,
  fontStyle: 'normal' as const,
}

const palette: Partial<Palette> = {
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  primary: {
    main: '#000000',
    dark: '#000000',
    light: '#bfbfbf',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#ffffff',
    light: '#bfbfbf',
    dark: '#000000',
    contrastText: '#000000',
  },
  text: {
    primary: '#6d6d6d',
    secondary: '#000000',
    disabled: '#b6b6c4',
    hint: '#000000',
  },
  divider: '#ffffff',
  background: {
    default: '#ffffff',
    paper: '#f7f7f7',
  },
}

const typography = {
  fontFamily: 'Caboto, Roboto, sans-serif',
  fontSize: 14,
  letterSpacing: 'normal',
  h1: {
    fontSize: '2rem',
    color: palette.text?.secondary,
    fontWeight: 400,
    marginBottom: 22,
  },
  h2: {
    fontSize: '2rem',
    color: palette.text?.secondary,
    fontWeight: 400,
    marginBottom: 22,
  },
  h3: {
    fontSize: 14,
  },
  h5: {
    fontSize: '1rem',
    color: palette.text?.secondary,
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    color: palette.text?.secondary,
  },
  subtitle2: {
    fontSize: '1rem',
    color: palette.text?.secondary,
    fontWeight: 600,
  },
  body2: {
    color: palette.text?.secondary,
    letterSpacing: 1.11,
  },
}

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [caboto],
    },
  },
  MuiButton: {
    root: {
      borderRadius: 6,
      textTransform: 'none',
    },
    contained: {
      backgroundColor: palette.primary?.dark,
      color: palette.primary?.contrastText,
      '&:hover': {
        backgroundColor: palette.primary?.dark,
        opacity: 0.7,
      },
    },
  },
  MuiLink: {
    root: {
      letterSpacing: 'normal',
    },
  },
  MuiTableCell: {
    root: {
      letterSpacing: 'normal',
    },
  },
  MuiStepLabel: {
    label: {
      fontSize: 14,
      lineHeight: 1.29,
      letterSpacing: 'normal',
      fontWeight: 500,
    },
  },
  MuiStepIcon: {
    root: {
      width: 14,
      height: 14,
    },
    text: {
      fontSize: 0,
    },
  },
  MuiStepConnector: {
    line: {
      height: 2,
    },
    alternativeLabel: {
      top: 6,
      left: 'calc(-50% + 4px)',
      right: 'calc(50% + 4px)',
    },
  },
}

export const defaultTheme = createTheme({
  palette,
  typography,
  overrides,
  ...commonTheme,
})
