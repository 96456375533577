import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { OrderPage } from '../pages/OrderPage'
import { PageNotFound } from '../pages/PageNotFound'

export const Routes: React.FC = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Switch>
        <Route exact path={'/'} component={OrderPage} />
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  )
}
