import { useMemo } from 'react'
import { useTranslation } from '../hooks/useTranslation'
import { OrderStep } from '../types/order.d'

export const useSteps = (canceledStatus: boolean) => {
  const { t } = useTranslation()
  return useMemo(() => {
    const getStepOption = (stepNumber: OrderStep) => ({
      value: stepNumber,
      label: t(`statusStepLabel${stepNumber}`),
    })
    return [
      getStepOption(OrderStep.Received),
      getStepOption(OrderStep.Processing),
      getStepOption(OrderStep.ReadyToShip),
      getStepOption(OrderStep.Sent),
      getStepOption(canceledStatus ? OrderStep.Canceled : OrderStep.Delivered),
    ]
  }, [canceledStatus, t])
}
