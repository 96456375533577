import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from '../../hooks/useTranslation'
import { ContactBox } from './ContactBox'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  helpBox: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  chatBox: {
    textAlign: 'center',
  },
  contactBoxContant: {
    width: '67%',
    maxWidth: '14rem',
    margin: 'auto',
  },
  phone: {
    textDecoration: 'none',
  },
  contactBlock: {
    '@media print': {
      flexBasis: '50%',
    },
  },
  contactLink: {
    '@media print': {
      '&::after': {
        content: '"("attr(href)")"',
        display: 'inline-block',
        position: 'absolute',
        bottom: -30,
      },
    },
  },
}))

export const ContactInfo: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { order } = useSelector((s) => s.order)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <>
      <Typography variant="h2">{t('contactUs')}</Typography>
      <Grid container spacing={isDesktop ? 10 : 2} alignItems="stretch">
        <Grid item xs={12} sm={6} md={6} className={classes.contactBlock}>
          <ContactBox className={classes.helpBox}>
            <Typography variant="h5" gutterBottom>
              {t('contactsNeedHelp')}
            </Typography>
            <Typography
              variant="body2"
              component="a"
              href={`tel:${order.contactuUsphone}`}
              className={classes.phone}
            >
              {t('contactsPhoneNumber')} {order.contactuUsphone}
            </Typography>
            <Typography variant="body2">
              {t('contactsDates')} {order.contactUpsPhoneTime}
            </Typography>
          </ContactBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classes.contactBlock}>
          {order.whatsAppLink && (
            <ContactBox className={classes.chatBox}>
              <Box className={classes.contactBoxContant}>
                <Typography variant="h5" gutterBottom>
                  {t('contactsСhatTitle')}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  component="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={order.whatsAppLink}
                  className={classes.contactLink}
                >
                  {t('contactsСhatButton')}
                </Button>
              </Box>
            </ContactBox>
          )}
        </Grid>
      </Grid>
    </>
  )
}
