import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { withTheme } from './Theme'
import { makeStyles } from '@material-ui/core'
import { Routes } from './Routers'
import { ErrorBoundary } from 'react-error-boundary'
import store from './store'
import { ErrorFallback } from '../components/ErrorMessage'
import { Loading } from '../components/Loading'

const useStyles = makeStyles({
  '@global': {
    'html, body, #root': {
      height: '100%',
      width: '100%',
      fontSize: '14px',
      overflow: 'auto',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      '@media print': {
        height: 'auto',
        overflowX: 'hidden',
      },
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
})

const BaseWithoutTheme: React.FC = () => {
  useStyles()
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes />
    </ErrorBoundary>
  )
}

const Base = withTheme(BaseWithoutTheme)

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <Base />
      </Suspense>
    </Provider>
  )
}

export default App
